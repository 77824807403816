

@import url('./css/fonts.css?z=1486478439337');


/*Arman #155*/
/*@media (max-width:767px){*/
/*    .MuiPaper-root.MuiAppBar-root.MuiAppBar-positionFixed.MuiAppBar-colorPrimary {*/
/*        height: 30px;*/
/*        max-height: 30px;*/
/*    }*/

/*    !* Arman #17*!*/
/*    .screens {*/
/*        margin-top: 30px;*/
/*    }*/

/*    .calculator_body .screens {*/
/*        margin-top: 45px;*/
/*    }*/
/*}*/

body {
    margin: 0px !important;
    background-color: #E6E6E6;
    font-family: Nunito-Regular !important;
    color: #333;
    -webkit-font-smoothing: antialiased; /* Fix for webkit rendering */
    -webkit-text-size-adjust: 100%;
}

.curveDiv {
    border-bottom-right-radius: 175px 20px;
    border-bottom-left-radius: 175px 20px;
    border: 1px solid transparent;
    border-top: none;
    height: 62px;
    width: 100%;
    position: relative;
    transform: rotate3d(3, 1, 170, 180deg);
    background-color: white;
}
.curveDiv:before,
.curveDiv:after {
    content: '';
    display: block;
    position: absolute;
    left: -1px;
}
.curveDiv:before {
    height: 20px;
    width: 100%;
    width: calc(100% + 1px);
    border-bottom-right-radius: 175px 20px;
    border-bottom: 1px solid transparent;
    border-right: solid 1px transparent;
    border-left: solid 1px transparent;
    top: -1px;
    border-bottom-left-radius: 175px 20px;
    background: #E6E6E6;
}
.curveDiv:after {
    height: calc(100% - 18px);
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    top: 19px;
}


.slider {
    width: 100%;
    text-align: center;
    overflow: hidden;
    background-color: #FCFCFC;
}

.slides {
    display: flex;

    overflow-x: auto;
    scroll-snap-type: x mandatory;



    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;

    /*
    scroll-snap-points-x: repeat(300px);
    scroll-snap-type: mandatory;
    */
}
.slides::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
.slides::-webkit-scrollbar-thumb {
    background: black;
    border-radius: 10px;
}
.slides::-webkit-scrollbar-track {
    background: transparent;
}
.slides > div {
    scroll-snap-align: start;
    flex-shrink: 0;
    width: 35%;
    height: 60px;
    margin-right: 4px;
    border-radius: 10px;
    transform-origin: center center;
    transform: scale(1);
    transition: transform 0.5s;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
}
/*Arman #145*/
.redCheckbox, .greenCheckbox, .orangeCheckbox, .blueCheckbox {
    align-items: unset !important;
}

.redCheckbox input[type='radio'] {
    -webkit-appearance:none;
    width:18px;
    height:18px;
    border:1px solid transparent;
    border-radius:50%;
    outline:none;
    box-shadow:0 0 5px 0px #939393;
}

.redCheckbox input[type='radio']:hover {
    box-shadow:0 0 5px 0px #939393;
}

.redCheckbox input[type='radio']:before {
    content:'';
    display:block;
    width:50%;
    height:50%;
    margin: 26% auto;
    border-radius:50%;
}

.redCheckbox input[type='radio']:checked:before {
    background:#FF0000;
}


.greenCheckbox input[type='radio'] {
    -webkit-appearance:none;
    width:18px;
    height:18px;
    border:1px solid transparent;
    border-radius:50%;
    outline:none;
    box-shadow:0 0 5px 0px #939393;
}

.greenCheckbox input[type='radio']:hover {
    box-shadow:0 0 5px 0px #939393;
}

.greenCheckbox input[type='radio']:before {
    content:'';
    display:block;
    width:50%;
    height:50%;
    margin: 26% auto;
    border-radius:50%;
}

.greenCheckbox input[type='radio']:checked:before {
    background:#67B11D;
}

.orangeCheckbox input[type='radio'] {
    -webkit-appearance:none;
    width:18px;
    height:18px;
    border:1px solid transparent;
    border-radius:50%;
    outline:none;
    box-shadow:0 0 5px 0px #939393;
}

.orangeCheckbox input[type='radio']:hover {
    box-shadow:0 0 5px 0px #939393;
}

.orangeCheckbox input[type='radio']:before {
    content:'';
    display:block;
    width:50%;
    height:50%;
    margin: 26% auto;
    border-radius:50%;
}

.orangeCheckbox input[type='radio']:checked:before {
    background:#D6C70C;
}


.blueCheckbox input[type='radio'] {
    -webkit-appearance:none;
    width:18px;
    height:18px;
    border:1px solid transparent;
    border-radius:50%;
    outline:none;
    box-shadow:0 0 5px 0px #939393;
}

.blueCheckbox input[type='radio']:hover {
    box-shadow:0 0 5px 0px #939393;
}

.blueCheckbox input[type='radio']:before {
    content:'';
    display:block;
    width:50%;
    height:50%;
    margin: 26% auto;
    border-radius:50%;
}

.blueCheckbox input[type='radio']:checked:before {
    background:#1CB7D3;
}





.grayCheckbox input[type='radio'] {
    -webkit-appearance:none;
    width:18px;
    height:18px;
    border:none;
    border-radius:50%;
    outline:none;
    box-shadow:none;
}

.grayCheckbox input[type='radio']:hover {
    box-shadow: none;
}

.grayCheckbox input[type='radio']:before {
    content:'';
    display:block;
    width:50%;
    height:50%;
    margin: 26% auto;
    border-radius:50%;
}

.grayCheckbox input[type='radio']:checked:before {
    background:gray;
}

.icons {
    width: 19px;
    height: 19px;
}

.css-3a8osk {
    width: 30px !important;
    height: 30px !important;
    border: 9px solid !important;
    border-bottom-color: transparent !important;
    margin-top: 50px;
    -webkit-animation: animation-s8tf21 0.75s 0s infinite linear !important;
    animation: animation-s8tf21 0.75s 0s infinite linear !important;
}


@keyframes animation-s8tf21 {
    0%   {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    50% {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}




.spinner-container {
    margin-top: 55px;
}
.spinner-circle {
    width: 50px;
    height: 50px;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&1+0,0+77 */
    background: -moz-linear-gradient(top, #fff 0%, rgba(255, 255, 255, 0.9) 30%, rgba(255, 255, 255, 0.7) 50%, rgba(255, 255, 255, 0.5) 70%, rgba(255, 255, 255, 0.2) 90%, rgba(255, 255, 255, 0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #fff 0%, rgba(255, 255, 255, 0.9) 30%, rgba(255, 255, 255, 0.7) 50%, rgba(255, 255, 255, 0.5) 70%, rgba(255, 255, 255, 0.2) 90%, rgba(255, 255, 255, 0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(90deg, #fff 0%, rgba(255, 255, 255, 0.9) 30%, rgba(255, 255, 255, 0.7) 50%, rgba(255, 255, 255, 0.5) 70%, rgba(255, 255, 255, 0.2) 90%, rgba(255, 255, 255, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */

    border-radius: 50%;
    animation: anim 1s infinite linear;
    position: relative;
}
.spinner-circle:after {
    position: absolute;
    display: block;
    top: 10px;
    left: 10px;
    width: 30px;
    height: 30px;
    content: "";
    background-color: #D20014;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    overflow: hidden;
}
@keyframes anim {
    0%   {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    50% {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.home_footer {
	left: 0px !important;
}



a {
	color: #CC0000;
}
.fulldescription li {
	color: #7b7b7b;
	padding: 5px 0px 5px 10px;
	font-size: 16px;
}
.fulldescription ul {
	margin: 0px;
	padding-left: 20px;
}
@media (min-width:1280px){



}


.MuiGrid-container, .MuiGrid-root, body .MuiGrid-grid-xs-12 {
    float: none;
	margin: 0px auto;
	left: auto;
}
.MuiButtonBase-root.MuiButton-root {
	border-radius: 0px;
	box-shadow: none;
	
}

input:active, input:focus,  input:hover {
	border: solid 1px #666666 !important;
}
header .MuiButtonBase-root {
	height: 62px;
	box-sizing: border-box;
	border-bottom-width: 3px !important;
}

#nternehmensdaten {
	margin-top: 104px;
	height: 44px;
	font-size: 32px;
}
#unternehmenslogo {
	margin-top: 60px;
	font-size: 20px;
}
.MuiButtonBase-root.MuiButton-root {
	height: 72px;
	font-size: 24px;
	background-color: #fff;
	color: #000;
	border-radius: 13px;
	margin-bottom: 20px;
}

#clogo_cover {
	border: 2px dashed #666666;
}



.makeStyles-crossIcon-21 {
margin-left: 12px;
}

.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorRight p {
	padding: 25px 0px 15px 0px;
	font-size: 22px;
	line-height: 30px;
}

.MuiPaper-root.MuiDrawer-paper .MuiListItemText-root span {
	font-size: 18px;
	line-height:25px;
}

.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorRight {
	max-width: 365px;
	height: 100vh;
	overflow-y: auto;
}
.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorRight > div {
	margin: 0px;
	padding: 0px 35px;
}
.MuiListItem-gutters.MuiListItem-root {
	padding: 0px;
}



.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorRight li {
	padding: 0px 0px 20px 0px !important;
	margin: 0px 0px 20px 0px;
	border-bottom: solid 1px #999999;
	width:100%;
}
.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorRight li:last-child {
	border: none;
}

.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorRight li a {
	padding: 20px 0px 20px 35px;
	margin: 0px -35px 0px -35px;
	display: block;
}
.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorRight li a:hover{
	background-color:lightgray;
}
.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorRight li a > div:hover{
	background-color:transparent;
}
.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorRight .MuiListItemText-root {
	padding: 0px !important;
	margin: 0px;
}
.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorRight li::after {
display:none;
}
/* modified by Sascha - fixing mobile wheel and top bar on ipad - Arman #155*/
/*@media (min-width:768px){*/
.MuiPaper-root.MuiAppBar-root.MuiAppBar-positionFixed.MuiAppBar-colorPrimary {
	height: 95px;
	max-height: 95px !important;
}
.screens {
	margin-top: 95px;
}
/*}*/

@media (max-width:1439px){
.MuiGrid-container, .MuiGrid-root, body .MuiGrid-grid-xs-12 {
max-width: 100%;
}
.MuiContainer-maxWidthLg {
max-width: 100%;
}	
}
@media (min-width:1440px){
	
body .MuiContainer-maxWidthLg {
/* remove max width of calculator and footer */
/*max-width: 1440px;*/
}
body .MuiGrid-container, body .MuiGrid-root, body .MuiGrid-grid-xs-12 {
/* remove max width of calculator and footer */
/* max-width: 1440px;*/
float: none;
margin: 0px auto;
left: auto;
}

.home_footer {
    /* fix buttons in footer for full width on desktop */
    /* left: calc(50% - 770px ) !important;*/

}
.home_footer .MuiButtonBase-root.MuiButton-root {
    /* fix buttons in footer for full width on desktop */
    /* left: 5%; */

}
}



.MuiGrid-root label.MuiFormControlLabel-root, .MuiGrid-root label.MuiFormLabel-root 
 {
	margin-top: 25px;
	line-height: 24px;
	font-size: 16px !important;
	margin-bottom: 9px;
}
#laden {
	margin-bottom: 25px;
}
#bitte {
	margin-top: 52px;
	margin-bottom: 30px;
}
.MuiFormControlLabel-root span.MuiIconButton-label {
	border: solid 1px #C7C7C7;
	padding: 0px;
	margin: 0px;
	box-sizing: border-box;

}
h6 {
	font-size: 16px !important;
}

.MuiFormControlLabel-label span {
	position: relative;
	top: 2px;
}
.screens > .MuiGrid-root.MuiGrid-container {
	padding-left: 22px;
	padding-right: 22px;
}
.calculator_root .screens > .MuiGrid-root.MuiGrid-container {
padding:0px;
}
.MuiGrid-root.createpractice, .MuiGrid-root.downloadpdf {
	padding-top: 52px;
	padding-left:22px;
	padding-right:22px;
}
#vorschau {
	margin-top: 45px;
}
#hinterlegen {
margin-top: 55px;
margin-bottom: 40px;
}


#submit {
	margin-top: 50px;
	margin-bottom:100px;
}
.user-form-button-send {
    margin-bottom: 25px !important;
}
.user-form-button-pdf {
    margin-top: 25px !important;
    margin-bottom: 100px !important;
}

.MuiGrid-root.MuiGrid-container .MuiFormControl-fullWidth {
	width: 100%;
	margin-top: 0px;
}

body .MuiTabs-root {
min-height: 62px;
}
#rtabs .MuiPaper-root {
	max-height: 62px;
}
#rtabs .MuiTab-wrapper {
	font-size: 16px;
	line-height: 22px;
}
.MuiTabs-flexContainer {
height: 100%;
}

.carousel-arrow.block.carousel-next {
	background-image:url('images/icons/Chevron-Right.svg');
	background-repeat: no-repeat;
	background-position: 9px 8px;
	background-size: 19px 19px;
}

.carousel-arrow.block.carousel-prev {
	background-image:url('images/icons/Chevron-Left.svg');
	background-repeat: no-repeat;
	background-position: 7px 8px;
	background-size: 19px 19px;
}
.carousel-arrow::before {
	display: none;
}

#close{
	background-image:url('images/icons/Cross-calculator.svg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: 19px 19px;	
}
.MuiToolbar-gutters h6 {
	
	font-family:Nunito-ExtraBold;
}



.MuiTypography-root p {
	margin-bottom: 25px;
}

.MuiPaper-root.MuiPaper-rounded {
	max-height: 95vh; /* #140 ArmanI. Änderung war 90vh - wegen anderen Dialogen zurückgesetzt  */
	max-width: 1000px;
}

#alle p {
	font-size: 16px;
	line-height: 24px;
}

#alle ul {
	padding-left: 16px;
}
#alle li {
	margin-bottom: 10px;
}
.calculator_body .MuiGrid-root.mbody {
    /* remove padding for correct alignment of headings and
       content in product info dialog */
	/* padding: 20px !important; */
}
.mbody  h6 {
	font-size2: 32px !important;
}

.MuiGrid-root .rcats {
	float: left;
	width: 171px;
	height: 103px;
	margin: 8px;
	max-width: calc(50% - 16px );
}
.mbody  h3{
font-size:22px;
line-height:30px;	
}
.mbody h4{
font-size:18px;
line-height:25px;	
}
.MuiGrid-root .mbody {
	max-height: calc(85vh - 190px ); /* #140 ArmanI. Änderung war 90vh - wegen anderen Dialogen zurückgesetzt */
	margin-top: 30px;
}
.mbody h1,
.mbody h2,
.mbody h3,
.mbody h4,
.mbody h5,
.mbody h6
{
margin-top:8px;
margin-bottom:13px;
color: #000;
}
.modaltitle h6 {
	font-size: 32px !important;
}
#rsocial {
	margin: 30px 0px;
}
#rsocial > div{
width:30%;
float:left;
}
#rsocial a {
	margin-bottom: 10px;
	background-repeat: no-repeat;
	background-position: 0px 0px;
	background-size: 100% 100%;
	min-height: 40px;
	width: 100%;
	display: block;
	float: left;
}

#rcontact span {
	margin-bottom: 10px;
	display: inline-block;
	background-repeat: no-repeat;
	background-position: 0px 0px;
	background-size: 20px 20px;
	min-width: 40px;
	min-height: 30px;
	display: inline-block;
	float: left;
}
#rcontact ul {
	list-style-type: none;
	padding-left: 0px;
	margin-top: 0px;
}
#rcontact ul li {
	display: block;
	clear: both;
}

#rcontact #location{
	background-image:url('images/icons/Location.svg');
}
#rcontact #tel{
	background-image:url('images/icons/Phone.svg');
}
#rcontact #fax{
	background-image:url('images/icons/fax.svg');
}
#rcontact #email{
	background-image:url('images/icons/Mail.svg');
}

#rsocial #facebook{
	background-image:url('images/icons/facebook.svg');
}
#rsocial #youtube{
	background-image:url('images/icons/youtube.svg');
}
#rsocial #linkedin{
	background-image:url('images/icons/linkedin.svg');
}

#modalwindow p {
	font-size: 16px;
	padding-bottom: 31px;
	margin:0px;
}
#modalwindow p, #modalwindow li {
	color: #7b7b7b;
}
#modalwindow img {
	max-width: 100%;
}
#modalwindow > .MuiGrid-root {
	padding-bottom: 20px; /*ArmanI. Padding mobile Version zu niedrig */
}
.align-top .MuiGrid-root.MuiGrid-container {
	align-items: normal;
}
#modalwindow input[type="radio"] {
	margin-right: 22px;
}

#rtabs {
margin-left: -22px;
margin-right: 20px;
}
#rtabs .MuiPaper-root {
width: calc( 100% + 45px);
}
.modalclose {
	display: none !important;
}
body.calculatorbody{
overflow:auto !important;
}
#product {
display:none;
}

#moreinfo{
cursor:pointer;
cursor:hand;
}
/*ArmanI. #136 */
.nutzungbestimmungen{
    text-align: center;
    font-family: 'Nunito-ExtraBold';
}
/*ArmanI. Tasten Cookie Stylen ändern */
#c_submit, #c_submit-1 {
	background: #7DBC3D;
    color: #ffffff;
}
#c_submit:active, #c_submit:focus, #c_submit:hover, #c_submit-1:active, #c_submit-1:focus,  #c_submit-1:hover{
    color: #000000;
}
/*ArmanI. Tasten Cookie Stylen #33 */
#c-footer, #c-footer-1{
    background: #fff;
    position: sticky;
}
/*ArmanI. Tasten Cookie Stylen #33 */
#c-footer-1{
    bottom: 0px;
}

/*ArmanI. Tasten Cookie Stylen ändern */
.MuiButton-contained.Mui-disabled {
	color: rgba(0, 0, 0, 0.26);
	box-shadow: none;
	background-color: rgba(0, 0, 0, 0.12);
}

.modaltitle h2 {
	font-size: 32px; /* ArmanI. Nutzungbestimungen Größe und Cookie Auswahl #116 - wegen Konflikten zurückgesetzt */
	line-height: 44px;
	margin: 0px;
}

#rsocial div {
	margin-bottom: 5px;
	line-height: 1.5;
}

#farbkodierungen p {
	padding-bottom: 0px;
}

/*#undso {
	list-style: decimal;
}*/
#undso p {
	padding-bottom: 10px;
	margin-top: -10px;
}
#Farbkodierungen {
	list-style-type: none;
	list-style-position: inherit;
	padding-left: 0px !important;
	margin-bottom: 31px;
}
#Farbkodierungen li {
	display: flex;
}
#Farbkodierungen span {
	min-width: 80px;
}
#modalwindow .rcats p {
	padding: 0px;
}
.MuiTab-textColorPrimary {
    border-bottom: 3px solid transparent !important;
}
.MuiTab-textColorPrimary.Mui-selected {
     border-bottom: 3px solid #D20014 !important;
}

/* CSS by Sascha and Arman */

#pdf-viewer-dialog .MuiPaper-root.MuiPaper-rounded,
#pdf-viewer-dialog body .MuiContainer-maxWidthLg {
    max-width: none !important;
}

#pdf-viewer-dialog .MuiDialog-paper {
    overflow-y: auto;
    max-height: 100%;
}

@media screen and (max-width: 767px) {
    #pdf-viewer-dialog .MuiDialog-paper {
        max-height: 90%;
    }
}

sub, sup {
    line-height: 0;
}

/*ArmanI. U-wert und Einsparrungen Stylen Ich habe ids benutzt, weil manchmal class funktioniert nicht Stylen sind unterschiedlich*/
.value{
    display: inline-block !important;
}
.masseinheit{
    font-size: 16px !important;
    display: inline !important;
}
#dveuro1, #dveuro2{
    display: inline !important;
}
#dvkg1, #dvkg2{
    display: inline !important;
}
#mveuro1, #mveuro2{
    display: inline !important;
}
#mvkg1, #mvkg2{
    display: inline !important;
}

#c-container .MuiGrid-root .mbody {
    max-height: 90vh; /* #140 ArmanI. - hierher verschoben damit anderen Dialoge nicht betroffen sind */
    margin-top: 15px;
}

#consent-container .MuiGrid-root .mbody {
    max-height: 90vh; /* #140 ArmanI. - hierher verschoben damit anderen Dialoge nicht betroffen sind */
    margin-top: 0;
}

#c-container .MuiPaper-root.MuiPaper-rounded,
#consent-container.MuiPaper-root.MuiPaper-rounded {
    max-height: 90vh; /* #140 ArmanI. - hierher verschoben damit anderen Dialoge nicht betroffen sind */
    max-width: 1000px;
}

#c-container h2,
#consent-container h2 {
    font-size: 24px; /* ArmanI. Nutzungbestimungen Größe und Cookie Auswahl #116 - zur Vermeidung von Konflikten
    spezifisch für Cookie Dialog und Einverständniserklärung gesetzt, zudem Fix da .modaltitle nichts auswählt */
}
/*Datenschutz*/
.dsel{
    word-break: break-word;
}
/* fix padding in dialogs (much more than before) */
#modalwindow > .MuiGrid-root:nth-child(2) {
    padding: 30px !important;
}

@media screen and (max-width: 786px) {
    /* no additional padding for cookie and consent dialogs on mobile (differs from the other dialogs) */
    #c-container #modalwindow > .MuiGrid-root:nth-child(2){
        padding: 0px 30px 0px 30px !important;
    }

    #modalwindow > .MuiGrid-root:first-child {
        padding-bottom: 0px;
    }

    /* lower margin ob mobile (avoiding line break in color code lists) */
    #modalwindow input[type="radio"] {
        margin-right: 18px;
    }

    #terms-of-use-container .modaltitle h2 {
        font-size: 24px;
        line-height: 36px;
    }
}

/* align modal titles */
.modaltitle h2 {
    /* remove alignment (using flex) */
    /* text-align: center; */
    font-weight: bold;
}

/*ArmanI. #159*/
/*@media (min-width:768px) {*/

    .prodinf {
        max-width: 48% !important;
    }

    /* add separate classes for columns and set left margin
       of first element to 0px to enable alignment with heading */
    .prodinf:first-child {
        margin-left: 0px !important;
        margin-right: 7px !important;
    }
    .prodinf:last-child {
        margin-left: 7px !important;
        margin-right: 0px !important;
    }
/*}*/

@media screen and (orientation: landscape) and (max-height:599px) {
    .calculatorbody #rotateDevice {
        display: block !important;
    }
}

#maxUploadSize {
    font-weight: bold;
    text-align: center;
    color: #D20014;
    font-style: italic;
}

@media only screen and (max-height: 568px) and (min-height: 500px) {

    #c_submit, #c_submit-1 {
        font-size: 18px !important;
    }

}

#rockwool-link > span {
    color: #D20014;
}

@media only screen and (min-height: 569px) and (max-height: 640px) {

    #c_submit, #c_submit-1 {
        font-size: 18px !important;
    }

}

@media only screen and (min-height: 641px) and (max-height: 653px) {

    @media only screen and (max-width: 280px) {
        #c_submit, #c_submit-1 {
            font-size: 18px !important;
        }

        #c-container h2, #consent-container h2 {
            font-size: 21px;
        }
    }

}

.tief{
    font-size: 10px;
}

#rotateDevice {
    width: 100%;
    height: 100%;
    background: #d20014;
    z-index: 10000;
    position: fixed;
    color: #fff;
    display: none;
    top: 0;
    left: 0;
}

#innerRotateDevice {
    background: url(/rotate.png) no-repeat right 1em;
    font-size: 1.5em;
    width: 200px;
    padding-top: 10px;
    padding-right: 20px;
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -100px;
    margin-top: -60px;
}