@font-face {
    font-family: 'Avenir-Bold';
    font-weight: normal;
    font-style: normal;
    src: url('./fonts/AvenirNextLTPro-Demi.otf');
}

@font-face {
  font-family: 'Nunito-ExtraBold';
  font-weight: normal;
  font-style: normal;
  src: url('./fonts/AvenirNextLTPro-Bold.otf');
}

@font-face {
    font-family: 'Nunito-Regular';
    font-weight: normal;
    font-style: normal;
    src: url('./fonts/AvenirNextLTPro-Regular.otf');
}

@font-face {
  font-family: 'Avenir-Italic';
  font-weight: normal;
  font-style: normal;
  src: url('./fonts/AvenirNextLTPro-It.otf');
}



@font-face {
  font-family: 'ClearSans-Regular';
  font-weight: normal;
  font-style: normal;
  src: url('./fonts/ClearSans-Regular.ttf');
}

@font-face {
  font-family: 'ClearSans-Bold';
  font-weight: normal;
  font-style: normal;
  src: url('./fonts/ClearSans-Bold.ttf');
}

@font-face {
  font-family: 'ClearSans-BoldItalic';
  font-weight: normal;
  font-style: normal;
  src: url('./fonts/ClearSans-BoldItalic.ttf');
}


@font-face {
  font-family: 'ClearSans-Italic';
  font-weight: normal;
  font-style: normal;
  src: url('./fonts/ClearSans-Italic.ttf');
}

@font-face {
  font-family: 'ClearSans-Light';
  font-weight: normal;
  font-style: normal;
  src: url('./fonts/ClearSans-Light.ttf');
}


@font-face {
  font-family: 'ClearSans-Medium';
  font-weight: normal;
  font-style: normal;
  src: url('./fonts/ClearSans-Medium.ttf');
}


@font-face {
  font-family: 'ClearSans-MediumItalic';
  font-weight: normal;
  font-style: normal;
  src: url('./fonts/ClearSans-MediumItalic.ttf');
}

@font-face {
  font-family: 'ClearSans-Thin';
  font-weight: normal;
  font-style: normal;
  src: url('./fonts/ClearSans-Thin.ttf');
}





@font-face {
  font-family: 'Nunito-Bold';
  font-weight: normal;
  font-style: normal;
  src: url('./fonts/Nunito-Bold.ttf');
}

@font-face {
font-family: 'Nunito-ExtraBold';
font-weight: normal;
font-style: normal;
src: url('./fonts/Nunito-ExtraBold.ttf');
}

@font-face {
  font-family: 'Nunito-Regular';
  font-weight: normal;
  font-style: normal;
  src: url('./fonts/Nunito-Regular.ttf');
}

@font-face {
font-family: 'Nunito-Italic';
font-weight: normal;
font-style: normal;
src: url('./fonts/Nunito-Italic.ttf');
}

